import React from 'react';
import '../App.css';
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <video src='/videos/video-1.mp4' autoPlay loop muted />
      <h1>Welcome to MIDAS</h1>
      {/* <p>Grow together </p> */}
      {/* <p>
      MIDAS Global Investments is established by experienced real estate investors who have experience working on various high projects . 
      We thrive on our local knowledge acquired over the years living in north DFW area. 
      Our leadership possesses a unique combination of technical expertise and business insight.
      We believe in long term relationship with our clients and are always committed to it.
      </p> */}
    </div>
  );
}

export default HeroSection;
